import { Constant } from './constant';

export const ALL_DCF_OFFICES: Constant[] = [
    { id: 2, name: 'Dimock Street Area Office (Roxbury)' },
    { id: 3, name: 'Hyde Park Area Office (Hyde Park)' },
    { id: 4, name: 'Harbor Area Office (Chelsea)' },
    { id: 5, name: 'Park Street Area Office (Dorchester)' },
    { id: 6, name: 'Arlington Area Office' },
    { id: 7, name: 'Coastal Area Office (Braintree)' },
    { id: 8, name: 'Cape Cod and Islands Area Office (Hyannis)' },
    { id: 9, name: 'Plymouth Area Office' },
    { id: 10, name: 'Fall River Area Office' },
    { id: 11, name: 'New Bedford Area Office' },
    { id: 12, name: 'Brockton Area Office' },
    { id: 13, name: 'Taunton/Attleboro Area Office' },
    { id: 14, name: 'Greater Lowell Area Office' },
    { id: 15, name: 'Framingham Area Office' },
    { id: 16, name: 'Haverhill Area Office (Bradford)' },
    { id: 17, name: 'Lawrence Area Office' },
    { id: 18, name: 'Cambridge/Burlington Area Office' },
    { id: 19, name: 'Metro North Area Office' },
    { id: 20, name: 'Cape Ann Area Office (Salem)' },
    { id: 21, name: 'Lynn Area Office' },
    { id: 22, name: 'Worcester East Area Office (West Boylston Dr.)' },
    { id: 23, name: 'Worcester West Area Office (Sudbury Street)' },
    { id: 24, name: 'South Central Area Office (Whitinsville)' },
    { id: 25, name: 'North Central Area Office (Leominster)' },
    { id: 26, name: 'Greenfield/Northampton Area Office' },
    { id: 27, name: 'Holyoke Area Office' },
    { id: 28, name: 'Springfield Area Office (High Street)' },
    { id: 29, name: 'Robert Van Wart Center (Springfield)' },
    { id: 30, name: 'Berkshire Area Office (Pittsfield)' },
    { id: 32, name: 'I\'m not sure' },
    { id: 31, name: 'Other' },
    { id: -1, name: 'Not Specified' },
];
