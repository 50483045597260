import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, pluck } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { User, UserAdapter } from '../models';
import { HttpClient } from '@angular/common/http';
import Bugsnag from '@bugsnag/js';


@Injectable()
export class UserAdminService {
    private api: string;

    constructor(private http: HttpClient, private userAdapter: UserAdapter) {
        this.api = environment.userAdminApiRoot;
    }

    public list(): Observable<User[]> {
        return this.http.get<any>(`${this.api}/listUsers`).pipe(
            pluck('Users'),
            map((users: any[]) => users.map(user => this.userAdapter.adapt(user)))
        );
    }

    public createUser(username: string, email: string, name: string): Observable<User> {
        const data = {
            username: username,
            email: email,
            name: name
        };
        return this.http.post<any>(`${this.api}/createUser`, data).pipe(
            pluck('User'),
            map((user: any) => this.userAdapter.adapt(user))
        );
    }

    public resendNewUserTempLogin(username: string): Observable<User> {
        /* This method should be used to resend the temporary login info if the
           user was unable to login before the expiration time */
        const data = {
            username: username
        };
        return this.http.post<any>(`${this.api}/resendUserLogin`, data).pipe(
            pluck('User'),
            map((user: any) => this.userAdapter.adapt(user))
        );
    }

    public getUser(username: string): Observable<User> {
        return this.http.get<any>(`${this.api}/getUser?username=${username}`).pipe(
            map((user: any) => this.userAdapter.adapt(user))
        );
    }

    public getUsersInGroup(groupName: string): Observable<string[]> {
        return this.http.get<any>(`${this.api}/listUsersInGroup?groupname=${groupName}`).pipe(
            pluck('Users'),
            map((users: any[]) => users.map(user => this.userAdapter.adapt(user).username))
        );
    }

    public getUserGroups(username: string): Observable<string[]> {
        return this.http.get<any>(`${this.api}/listGroupsForUser?username=${username}`).pipe(
            pluck('Groups'),
            map((groups: any[]) => groups.map(group => group.GroupName))
        );
    }

    private _wrapBooleanObservable(endpoint: string, params: Object): Observable<boolean> {
        return new Observable(observer => {
            this.http.post<any>(`${this.api}/${endpoint}`, params).subscribe(
                (_response: any) => {
                    console.log(_response);
                    observer.next(true);
                    observer.complete();
                },
                (err: any) => {
                    console.error(err);
                    Bugsnag.notify(err);
                    observer.next(false);
                    observer.complete();
                }
            );
        });
    }

    public resetUserPassword(username: string): Observable<boolean> {
        return this._wrapBooleanObservable('resetUserPassword', { username: username });
    }

    public enableUser(username: string): Observable<boolean> {
        return this._wrapBooleanObservable('enableUser', { username: username });
    }

    public disableUser(username: string): Observable<boolean> {
        return this._wrapBooleanObservable('disableUser', { username: username });
    }

    public makeUserAdmin(username: string): Observable<boolean> {
        const params = { username: username, groupname: 'Administrators' };
        return this._wrapBooleanObservable('addUserToGroup', params);
    }

    public removeUserAdmin(username: string): Observable<boolean> {
        const params = { username: username, groupname: 'Administrators' };
        return this._wrapBooleanObservable('removeUserFromGroup', params);
    }

    public updateUserAttributes(username: string, attributes: {}): Observable<boolean> {
        const userAttributes = [];
        for (const key in attributes) {
            if (attributes.hasOwnProperty(key)) {
                userAttributes.push({
                    Name: key,
                    Value: attributes[key]
                });
            }
        }
        const params = { username: username, userattributes: userAttributes };
        return this._wrapBooleanObservable('updateUserAttributes', params);
    }

}
