import { Injectable } from '@angular/core';
import { Adapter } from './adapter';
import { ALL_GENDERS } from './values/gender.model';
import { ALL_PRONOUNS } from './values/pronouns.model';
import { ALL_RACES } from './values/race.model';
import { ALL_RACE_ETHNICITIES } from './values/race-ethnicity.model';
import { ALL_ETHNICITIES } from './values/ethnicity.model';
import { ALL_PLACEMENTS } from './values/placement.model';

interface IChild {
    id: string;
    firstName: string;
    lastName: string;
    preferredName: string;
    dateOfBirth: Date;
    genderId: number;
    genderIds: number[];
    genderOther: string;
    pronounId: number;
    pronounOther: string;
    ethnicityId: number;
    raceEthnicityId: number;
    raceEthnicityOther: string;
    raceId: number;
    raceIds: number[];
    residence: string;
    placementId: number;
    placementOther: string;
    childGSIPK: string;
    version: number;
}

export class Child {
    private _id: string;
    public firstName: string;
    public lastName: string;
    public preferredName: string;
    public dateOfBirth: Date;
    public genderId: number;
    public genderIds: number[];
    public genderIdBools: boolean[];
    public genderOther: string;
    public pronounId: number;
    public pronounOther: string;
    public ethnicityId: number;
    public raceEthnicityId: number;
    public raceEthnicityOther: string;
    public raceId: number; // deprecated
    public raceIds: number[];
    public raceIdBools: boolean[];
    public residence: string;
    public placementId: number;
    public placementOther: string;
    public childGSIPK: string;
    public version: number;

    constructor(obj?: IChild) {
        this._id = obj && obj.id || null;
        this.firstName = obj && obj.firstName || '';
        this.lastName = obj && obj.lastName || '';
        this.preferredName = obj && obj.preferredName || '';
        this.dateOfBirth = obj && obj.dateOfBirth || null;
        this.genderId = obj && obj.genderId || -1; // deprecated
        this.genderIds = obj && obj.genderIds.map(i => +i) || [-1];
        this.genderOther = obj && obj.genderOther || '';
        this.pronounId = obj && obj.pronounId || -1;
        this.pronounOther = obj && obj.pronounOther || '';
        this.ethnicityId = obj && obj.ethnicityId || -1; //deprecated
        this.raceEthnicityId = obj && +obj.raceEthnicityId || -1;
        this.raceEthnicityOther = obj && obj.raceEthnicityOther || '';
        this.raceId = obj && obj.raceId || -1; // deprecated
        this.raceIds = obj && obj.raceIds.map(i => +i) || [-1]; // deprecated
        this.residence = obj && obj.residence || '';
        this.placementId = obj && obj.placementId || -1;
        this.placementOther = obj && obj.placementOther || '';
        this.childGSIPK = obj && obj.childGSIPK || '';
        this.version = obj && obj.version || 1;

        // For setting/maintaining checkboxes
        this.raceIdBools = ALL_RACES.filter(r => r.id > 0).map(r => this.raceIds.includes(r.id));
        this.genderIdBools = ALL_GENDERS.filter(g => g.id > 0).map(g => this.genderIds.includes(g.id));

        // Use raceId if raceEthnicityId is not set (helpful for older submissions)
        if (this.raceEthnicityId < 0 && this.raceIds.length > 0 && Math.min(...this.raceIds) > 0) {
            this.raceEthnicityId = this.raceIds[0];
        }
    }

    get id(): string {
        if (this._id) {
            return this._id.replace('CHILD#', '');
        }
        else if (this.childGSIPK) {
            return this.childGSIPK.replace('CHILD#', '');
        }
        return '';
    }

    get pronouns(): string {
        if (this.pronounId === 4) {
            // Choice selected is 'Other' and should have a custom-defined value
            return `Other: ${this.pronounOther}`;
        }
        else if (this.pronounId > 0) {
            return ALL_PRONOUNS.find(p => p.id === this.pronounId).name;
        }
        return '';
    }

    // deprecated
    get gender(): string {
        if (this.genderId > 0) {
            return ALL_GENDERS.find(g => g.id === this.genderId).name;
        }
        return '';
    }

    get genders(): string {
        if (this.genderIds.length > 0 && Math.min(...this.genderIds) > 0) {
            const genderNames = this.genderIds.map(id => ALL_GENDERS.find(g => g.id === id).name || '');
            if (this.genderOther) {
                // Add in any non-predefined gender entered by the user
                genderNames.push(this.genderOther);
            }
            return genderNames.join(', ');
        }
        return '';
    }

    get ethnicity(): string {
        if (this.ethnicityId > 0) {
            return ALL_ETHNICITIES.find(e => e.id === this.ethnicityId).name;
        }
        return '';
    }

    get raceEthnicity(): string {
        if (this.raceEthnicityId > 0) {
            return ALL_RACE_ETHNICITIES.find(e => e.id === this.raceEthnicityId).name;
        }
    }

    // deprecated
    get race(): string {
        if (this.raceId > 0) {
            return ALL_RACES.find(r => r.id === this.raceId).name;
        }
        return '';
    }

    get races(): string {
        if (this.raceIds.length > 0 && Math.min(...this.raceIds) > 0) {
            const raceNames = this.raceIds.map(id => ALL_RACES.find(r => r.id === id).name || '');
            return raceNames.join(', ');
        }
        return '';
    }

    get placement(): string {
        if (this.placementId > 0) {
            if (this.placementId === 5) {
                return this.placementOther;
            } else {
                return ALL_PLACEMENTS.find(p => p.id === this.placementId).name;
            }
        }
        return '';
    }

    public getFullName(): string {
        if (this.firstName || this.lastName) {
            return `${this.firstName} ${this.lastName}`;
        }
        return '[Name Unknown]';
    }

    public getSearchKey(): string {
        // Remove non-alphanumeric chars and force uppercase
        const fName = this.firstName.replace(/[\W]/g, '');
        const lName = this.lastName.replace(/[\W]/g, '');
        return `${lName.toUpperCase()}${fName.toUpperCase()}`;
    }

    public getNumberDOB(): string {
        if (this.dateOfBirth) {
            return this.getEUFormattedDOB().replace(/[\D]/g, '');
            // return `${this.dateOfBirth.replace(/[\D]/g, '')}`;
        }
        return '';
    }

    // Return date formatted as MM/DD/YYYY
    public getUSFormattedDOB(): string {
        if (this.dateOfBirth) {
            return this.dateOfBirth.toLocaleDateString('en-US', {year:'numeric',month:'2-digit',day:'2-digit'});
            // Date is stored in YYYY-MM-DD format
            /*const match = this.dateOfBirth.match(/(\d+)-(\d+)-(\d+)/);
            if (match.length >= 4) {
                return `${match[2]}/${match[3]}/${match[1]}`;
            }
            return '';*/
        }
        return '';
    }

    // Return date formatted as YYYY-MM-DD
    public getEUFormattedDOB(): string {
        if (this.dateOfBirth) {
            // ISO string is in form of 'YYYY-MM-DDTHH:mm:ss.zzz'
            return this.dateOfBirth.toISOString().split('T', 1)[0];
        }
        return '';
        // Date is stored in YYYY-MM-DD format
        //return this.dateOfBirth ? this.dateOfBirth : '';
    }
}

@Injectable()
export class ChildAdapter implements Adapter<Child> {
  DATE_REGEX = /\d{4}-\d{1,2}-\d{1,2}/;
  EST_OFFSET = 5 * 60 * 60 * 1000;  // 5 hours

  adapt(item: any): Child {
    return new Child({
        id: item && item.id || null,
        firstName: item && item.firstName || '',
        lastName: item && item.lastName || '',
        preferredName: item && item.preferredName || '',
        dateOfBirth: item && this.getOffsetDate(item.dateOfBirth) || null,
        genderId: item && item.genderId || -1,
        genderIds: item && item.genderIds || [item && item.genderId] || [-1],
        genderOther: item && item.genderOther || '',
        pronounId: item && item.pronounId || -1,
        pronounOther: item && item.pronounOther || '',
        ethnicityId: item && item.ethnicityId || -1,
        raceEthnicityId: item && item.raceEthnicityId || (item.raceIds && item.raceIds[0]) || (item && item.raceId) || -1,
        raceEthnicityOther: item && item.raceEthnicityOther || '',
        raceId: item && item.raceId || -1,
        raceIds: item && item.raceIds || [item && item.raceId] || [-1],
        residence: item && item.residence || '',
        placementId: item && item.placementId || -1,
        placementOther: item && item.placementOther || '',
        childGSIPK: item && item.childGSIPK || '',
        version: item && item.version || 1
    });
  }

  getOffsetDate(dateStr: string): Date {
    if (this.DATE_REGEX.test(dateStr)) {
        // If in YYYY-MM-DD format, we should assume the date is relative to EST as we are east coast based.
        // A non-precise way to do this is add 5 hours to the date. Since we only care about the date
        // and not the time, we don't need to account for DST
        return new Date(Date.parse(dateStr) + this.EST_OFFSET);
    }
    return new Date(dateStr);
  }
}
