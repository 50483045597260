import { Injectable } from '@angular/core';
import { Adapter } from './adapter';

export class User {
    public admin: boolean;
    constructor(
        public username: string,
        public password: string,
        public enabled: boolean,
        public created: Date,
        public modified: Date,
        public status: string,
        public attributes: { [name: string]: string }
    ) { }

    getId(): string {
        return this.attributes['sub'] || null;
    }

    getName(): string {
        return this.attributes['name'] || '';
    }

    getFirstName(): string {
        if (this.attributes['name']) {
            return this.attributes['name'].split(' ')[0];
        }
        return '';
    }

    getEmail(): string {
        return this.attributes['email'] || '';
    }

    getPhone(): string {
        return this.attributes['phone_number'] || '';
    }

    isAdmin(): boolean {
        return this.admin;
    }
}

@Injectable()
export class UserAdapter implements Adapter<User> {

    private static arrayToObject = (array: any) =>
      array.reduce((obj: any, item: any) => {
            obj[item['Name']] = item['Value'];
            return obj;
      }, {})

    /* Attributes may be provided as "Attributes" or "UserAttributes" */
    adapt(item: any): User {
        const attributes = item.Attributes || item.attributes || item.UserAttributes || item.userAttributes || {};
        return new User(
            item.Username || item.username || '',
            item.Password || item.password || null,
            item.Enabled || item.enabled || null,
            item.UserCreateDate || item.userCreateDate || null,
            item.UserLastModifiedDate || item.userLastModifiedDate || null,
            item.UserStatus || item.userStatus || '',
            (attributes instanceof Array) ? UserAdapter.arrayToObject(attributes) : attributes
        );
    }

}
