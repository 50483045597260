import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, expand, map, reduce } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { Child, ChildAdapter, FundingRequest, FundingRequestAdapter  } from '../models';


@Injectable()
export class ChildService {
    private api: string;

    constructor(
        private http: HttpClient,
        private cAdapter: ChildAdapter,
        private frAdapter: FundingRequestAdapter
    ) {
        this.api = `${environment.apiRoot}${environment.apiEndpoints.children}`;
    }

    list(childId: string = null): Observable<Child[]> {
        const endpoint = (childId) ? `${this.api}/${childId}` : this.api;
        return new Observable((observer: any) => {
            this.getChildItems(endpoint).pipe(
                expand((data, i) => {
                    return data.next ? this.getChildItems(endpoint, data.next.id, data.next.dob) : of();
                }),
                reduce((acc, data) => {
                    return acc.concat(data.results);
                }, []),
                catchError(error => observer.error(error))
            )
            .subscribe((children) => {
                observer.next(children);
                observer.complete();
            });
        });
    }

    get(childId: string): Observable<Child> {
        const endpoint = `${this.api}/${childId}`;
        return childId ?
            this.http.get<any>(endpoint).pipe(map(response => {
                // Adapt each item in the raw data array to a Child object
                return this.cAdapter.adapt(response.child);
            })) : of();
    }

    getFundingRequests(childId: string): Observable<FundingRequest[]> {
        const endpoint = `${this.api}/${childId}/Applications`;
        return new Observable((observer: any) => {
            this.getApplicationItems(endpoint).pipe(
                expand((data, i) => {
                    return data.next ? this.getApplicationItems(endpoint, data.next) : of();
                }),
                reduce((acc, data) => {
                    return acc.concat(data.results);
                }, []),
                catchError((error: any) => observer.error(error))
            )
            .subscribe((fundingRequests) => {
                observer.next(fundingRequests);
                observer.complete();
            });
      });
    }

    update(childId: string, updateParams: Object, version: number = null): Observable<Child> {
        // Send everything to the API, as the Lambda function will handle everything
        const endpoint = `${this.api}/${childId}`;
        if (!('version' in updateParams)) {
            if (version) {
                updateParams['version'] = version;
            } else {
                const message = `No version was provided while trying to update: ${childId}`;
                throw new Error(message);
            }
        }
        return childId ?
            this.http.post<any>(endpoint, updateParams).pipe(map(response => {
                // Adapt each item in the raw data array to a Child object
                return this.cAdapter.adapt(response.child);
            })) : of();
    }

    private getChildItems(endpoint: string, startId: string = null, startDOB: string = null): Observable<{next: any, results: Child[]}> {
        if (startId && startDOB) {
            endpoint += `?startId=${startId}&startDOB=${startDOB}`;
        }
        return this.http.get<any>(endpoint).pipe(
            map(response => {
                let nextIds = null;
                if ('lastEvaluatedKey' in response) {
                    nextIds = {
                        id: response.lastEvaluatedKey.PK,
                        dob: response.lastEvaluatedKey.SK
                    };
                }
                return {
                    next: nextIds,
                    results: response.children.map((item: any) => this.cAdapter.adapt(item))
                };
            })
        );
    }

    private getApplicationItems(endpoint: string, startKey: string = null): Observable<{next: string, results: FundingRequest[]}> {
        if (startKey) {
            endpoint += `?startKey=${startKey}`;
        }
        return this.http.get<any>(endpoint).pipe(
            map(response => {
                    // TODO rework this for composite key
                    const nextId = ('LastEvaluatedKey' in response) ? response.lastEvaluatedKey.requestId : null;
                return {
                    next: nextId,
                    results: response.applications.map((item: any) => this.frAdapter.adapt(item))
                };
            })
        );
    }
}
