import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MdbModalModule } from 'mdb-angular-ui-kit/modal';
import { MdbFormsModule } from 'mdb-angular-ui-kit/forms';
import { MdbValidationModule } from 'mdb-angular-ui-kit/validation';

import { PageHeaderModule } from '../../shared';
import { UsersRoutingModule } from './users-routing.module';
import { UsersComponent } from './users.component';
import { UserDetailComponent } from './user-detail/user-detail.component';
import { NewUserModalComponent } from './new-user-modal/new-user-modal.component';

@NgModule({
  declarations: [UsersComponent, UserDetailComponent, NewUserModalComponent],
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    MdbFormsModule,
    MdbValidationModule,
    MdbModalModule,
    UsersRoutingModule,
    PageHeaderModule
  ]
})
export class UsersModule { }
