
  // This file was autogenerated by dynamically running setEnv.ts and using dotenv for managing API key secrecy

  export const environment = {
    production: false,
    stage: 'staging',
    version: '3.3.0',
    bugSnagApiKey: '9fecf9ebd82c39bf681d70b766775090',
    apiRoot: 'https://bni738kn26.execute-api.us-east-1.amazonaws.com/Dev',
    userAdminApiRoot: 'https://h0di047vmb.execute-api.us-east-1.amazonaws.com/Dev',
    apiEndpoints: {
      fundingRequests: '/Application',
      notes: '/Note',
      children: '/Child'
    },
    cognitoConfiguration: {
      Auth: {
        identityPoolId: 'us-east-1:0ad1d2a2-b19b-44f7-9972-35e997945cd5',
        region: 'us-east-1',
        userPoolId: 'us-east-1_6tQ2RZsJu',
        userPoolWebClientId: '118qosjfkjj77krs5amuobigps',
        mandatorySignIn: true,
      }
    }
  };
