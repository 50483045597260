import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';

import { routerTransition } from '../../router.animations';
import { TITLE_COMMON } from '../../shared';
import { UserAdminService } from '../../shared/services';
import { NewUserModalComponent } from './new-user-modal/new-user-modal.component';
import { User } from '../../shared/models';
import { Breadcrumb } from '../../shared/modules/page-header/page-header.component';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
  animations: [routerTransition()]
})
export class UsersComponent implements OnInit {
    private modalRef: MdbModalRef<NewUserModalComponent> | null = null;
    adminUsers: string[];
    users: User[];
    pageTrail: Breadcrumb[];

    constructor(
        private userAdminService: UserAdminService,
        private titleService: Title,
        private toastr: ToastrService,
        private modalService: MdbModalService
    ) {
        this.pageTrail = [new Breadcrumb('Users', 'users', null)];
        this.titleService.setTitle('User Management' + TITLE_COMMON);
    }

    ngOnInit(): void {
        this.userAdminService.getUsersInGroup('Administrators').subscribe(
            (adminUsers: string[]) => {
                this.adminUsers = adminUsers;
                this.loadUsers();
            },
            (err: any) => {
                console.error(err);
                this.toastr.error('An error occurred while loading the Administrators group');
            }
        );
    }

    public openNewUserModal() {
        this.modalRef = this.modalService.open(NewUserModalComponent);
        this.modalRef.onClose.subscribe((_result: any) => {
            if (_result) {
                this.loadUsers(); // Update user list
            }
        },
        (error: any) => {
            if (error) {
                console.log(error);
            }
        });
    }

    private loadUsers(): void {
        this.userAdminService.list().subscribe(
            (results: User[]) => {
                this.users = results;
            },
            (err: any) => {
                console.error(err);
                this.toastr.error('An error occurred while loading users.', 'Loading Users Failed');
            }
        );
    }

    public enableLogin(user: User, isEnabled: boolean): void {
        let obs: Observable<boolean>;
        if (isEnabled) {
            obs = this.userAdminService.enableUser(user.username);
        } else {
            obs = this.userAdminService.disableUser(user.username);
        }
        const action = isEnabled ? 'enable' : 'disable';
        obs.subscribe(
            (_result: boolean) => {
                this.toastr.success(`${user.username}'s login was successfully ${action}d`);
                user.enabled = isEnabled;
            },
            (err: any) => {
                console.error(err);
                this.toastr.error(`Error while attempting to ${action} ${user.username}'s login`);
            }
        );
    }

    public resetPassword(username: string): void {
        this.userAdminService.resetUserPassword(username).subscribe(
            (success: boolean) => {
                if (success) {
                    this.toastr.success(`${username}'s password was successfully reset`, 'Password Reset');
                } else {
                    this.toastr.error(`Error while attempting to reset ${username}'s password`, 'Password Reset Failed');
                }
            }
        );
    }

    public resendTempPassword(username: string): void {
        this.userAdminService.resendNewUserTempLogin(username).subscribe(
            (_result: User) => {
                this.toastr.success(`${username}'s temporary new user password was resent`);
            },
            (err: any) => {
                console.error(err);
                this.toastr.error(`Error while attempting to resend ${username}'s temporary new user password`);
            }
        );
    }
}
