<div [@routerTransition]>
    <app-page-header [heading]="title" [breadcrumbs]="pageTrail"></app-page-header>

    <div class="row">

        <div class="col-md-6">

            <form *ngIf="user; else loading" [formGroup]="userForm" (ngSubmit)="onSubmit()">

                <mdb-form-control class="mb-4 mx-4" formGroupName="attributes">
                    <input mdbInput type="text" formControlName="name" id="name" class="form-control" required />
                    <label mdbLabel class="form-label" for="name">{{ 'Name' | translate }}</label>
                    <mdb-error *ngIf="name?.invalid && (name?.dirty || name?.touched)">
                        <span>A name is required</span>
                    </mdb-error>
                </mdb-form-control>

                <mdb-form-control class="mb-4 mx-4" formGroupName="attributes">
                    <input mdbInput type="email" formControlName="email" id="email" class="form-control" required />
                    <label mdbLabel class="form-label" for="email">{{ 'Email' | translate }}</label>
                    <mdb-error *ngIf="email?.invalid && (email?.dirty || email?.touched)">
                        <span>A valid email address is required</span>
                    </mdb-error>
                </mdb-form-control>

                <div class="d-flex justify-content-end me-4">
                    <button type="submit" [disabled]="userForm.invalid || (userForm.pristine && userForm.untouched)" class="btn btn-primary btn-sm mr-1">
                        {{'Save Changes' | translate}}
                    </button>
                </div>
            </form>

        </div>

        <div #toggles class="col-md-6">

            <form *ngIf="user; else loading" [formGroup]="userForm">

                <div class="form-check form-switch">
                    <input mdbCheckbox class="form-check-input" type="checkbox" id="loginEnabled" [checked]="isEnabled" (change)="enableLogin(!isEnabled)" title="Enable or disable user login" />
                    <label for="loginEnabled" class="form-check-label">{{ 'Login Enabled' | translate }}</label>
                </div>

                <div class="form-check form-switch">
                    <input mdbCheckbox class="form-check-input" type="checkbox" id="adminEnabled" [checked]="isAdmin" (change)="enableAdmin(!isAdmin)" title="Enable or disable admin permissions" />
                    <label for="adminEnabled" class="form-check-label">{{ 'Admin Permissions' | translate }}</label>
                </div>

            </form>

        </div>

    </div>

    <ng-template #loading>
        {{ 'Loading data' | translate }}...
    </ng-template>

</div>
